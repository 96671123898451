import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import { DateRange, SerializableDateRange, serializeDateRange } from 'utils/date.utils';
import { parseSearchText } from 'utils/search.utils';

import { DEFAULT_ORDER, DEFAULT_SEARCH_MODE } from 'constants/search.constants';
import { SearchModes } from 'types/search.types';

import { TagInputType } from 'components/@inputs/TagInput';

export enum AdvancedFiltersEnum {
  Keywords = 'keyword',
  Title = 'title',
  Intro = 'intro',
  Publisher = 'publisher',
  PageNr = 'pagenr',
  WordCountMin = 'wordcountmin',
  WordCountMax = 'wordcountmax',
  ExactQuery = 'exactquery',
  CollapseDuplicates = 'collapseduplicates',
  Author = 'author',
}

export enum FiltersEnum {
  Period = 'period',
  MediumTypeGroup = 'mediumTypeGroup',
  Source = 'source',
  SourceGroup = 'sourceGroup',
  Language = 'language',
  Country = 'country',
  Order = 'order',
  Edition = 'edition',
  Subsource = 'subsource',
  AdvancedFilters = 'advancedFilters',
  SearchMode = 'searchMode',
  Topics = 'topics',
}

interface FilterSubsource {
  sourceId: string;
  label: string;
  active: boolean;
}

const migrations = {
  '1': (state: SearchState) => {
    return {
      currentSearchFiltersId: state.currentSearchFiltersId,
    };
  },
};

export const searchPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.search-filters`,
  version: 1,
  storage,
  whitelist: ['currentSearchFiltersId'],
  // @ts-ignore
  migrate: createMigrate(migrations, { debug: false }),
};

export interface SearchState {
  currentSearchFiltersId?: number;
  searchText: string;
  hasSearched: boolean;
  page: number;
  perPage: number;
  isManual: boolean;
  openNewsObjectUuid?: string;
  filters: {
    [FiltersEnum.Period]: SerializableDateRange;
    [FiltersEnum.MediumTypeGroup]: {
      [key: string]: boolean;
    };
    [FiltersEnum.Source]: {
      [key: string]: boolean;
    };
    [FiltersEnum.SourceGroup]: {
      [key: string]: boolean;
    };
    [FiltersEnum.Language]: {
      [key: string]: boolean;
    };
    [FiltersEnum.Country]: {
      [key: string]: boolean;
    };
    [FiltersEnum.Edition]: {
      [key: string]: boolean;
    };
    [FiltersEnum.Subsource]: {
      [key: string]: FilterSubsource;
    };
    [FiltersEnum.Topics]: {
      [key: string]: boolean;
    };
    [FiltersEnum.Order]: string;
    [FiltersEnum.SearchMode]?: SearchModes;
    [FiltersEnum.AdvancedFilters]: {
      [AdvancedFiltersEnum.Keywords]: TagInputType[];
      [AdvancedFiltersEnum.Title]: string;
      [AdvancedFiltersEnum.Intro]: string;
      [AdvancedFiltersEnum.Author]: string;
      [AdvancedFiltersEnum.Publisher]: TagInputType[];
      [AdvancedFiltersEnum.PageNr]?: string;
      [AdvancedFiltersEnum.WordCountMin]?: string;
      [AdvancedFiltersEnum.WordCountMax]?: string;
      [AdvancedFiltersEnum.ExactQuery]: boolean;
      [AdvancedFiltersEnum.CollapseDuplicates]: boolean;
    };
  };
}

export const INITIAL_STATE: SearchState = {
  currentSearchFiltersId: undefined,
  hasSearched: false,
  searchText: '',
  page: 0,
  perPage: 20,
  isManual: false,
  openNewsObjectUuid: undefined,
  filters: {
    [FiltersEnum.Period]: {
      type: undefined,
      startDate: null,
      endDate: null,
    },
    [FiltersEnum.MediumTypeGroup]: {},
    [FiltersEnum.Source]: {},
    [FiltersEnum.SourceGroup]: {},
    [FiltersEnum.Language]: {},
    [FiltersEnum.Country]: {},
    [FiltersEnum.Edition]: {},
    [FiltersEnum.Subsource]: {},
    [FiltersEnum.Topics]: {},
    [FiltersEnum.SearchMode]: DEFAULT_SEARCH_MODE,
    [FiltersEnum.Order]: DEFAULT_ORDER,
    [FiltersEnum.AdvancedFilters]: {
      [AdvancedFiltersEnum.Keywords]: [],
      [AdvancedFiltersEnum.Title]: '',
      [AdvancedFiltersEnum.Intro]: '',
      [AdvancedFiltersEnum.Author]: '',
      [AdvancedFiltersEnum.Publisher]: [],
      [AdvancedFiltersEnum.PageNr]: undefined,
      [AdvancedFiltersEnum.WordCountMin]: undefined,
      [AdvancedFiltersEnum.WordCountMax]: undefined,
      [AdvancedFiltersEnum.ExactQuery]: false,
      [AdvancedFiltersEnum.CollapseDuplicates]: false,
    },
  },
};

export type AdvancedFiltersDataType = string | string[] | boolean | TagInputType[];

type AdvancedFiltersPayload = {
  data: AdvancedFiltersDataType;
  filter: AdvancedFiltersEnum;
};

const searchSlice = createSlice({
  name: 'search-filters',
  initialState: INITIAL_STATE,
  reducers: {
    FETCH_CURRENT_SEARCH_FILTERS_SUCCESS: (
      state,
      action: PayloadAction<{
        id: SearchState['currentSearchFiltersId'];
        currentFilters: Partial<SearchState['filters']>;
      }>,
    ) => {
      const { id, currentFilters } = action.payload;

      state.currentSearchFiltersId = id;
      state.filters = {
        ...state.filters,
        ...currentFilters,
        [FiltersEnum.AdvancedFilters]: {
          ...state.filters[FiltersEnum.AdvancedFilters],
          ...(currentFilters[FiltersEnum.AdvancedFilters] || {}),
        },
      };
    },
    SET_INITIAL_SEARCH_STATE: (
      state,
      action: PayloadAction<
        Pick<SearchState, 'searchText' | 'isManual' | 'hasSearched' | 'page'> &
          Pick<SearchState['filters'], FiltersEnum.SearchMode>
      >,
    ) => {
      const { searchMode, searchText, isManual, hasSearched, page } = action.payload;
      const searchModeValue = !searchMode ? DEFAULT_SEARCH_MODE : searchMode;

      state.hasSearched = hasSearched;
      state.isManual = isManual;
      state.searchText = searchText;
      state.filters[FiltersEnum.SearchMode] = isManual ? undefined : searchModeValue;
      state.page = page;
    },
    RESET_SEARCH_STATE: state => {
      state.hasSearched = false;
      state.isManual = false;
      state.searchText = '';
      state.page = 0;
      state.perPage = 20;
      state.openNewsObjectUuid = undefined;
    },
    UPDATE_IS_MANUAL: (state, action: PayloadAction<SearchState['isManual'] | undefined>) => {
      state.isManual = action ? !!action.payload : !state.isManual;
      state.filters[FiltersEnum.SearchMode] = state.isManual ? undefined : DEFAULT_SEARCH_MODE;
    },
    UPDATE_SEARCH_TEXT: (state, action: PayloadAction<SearchState['searchText']>) => {
      state.searchText = action.payload ? parseSearchText(action.payload) : '';
    },
    UPDATE_SEARCH_MODE: (state, action: PayloadAction<SearchState['filters']['searchMode']>) => {
      state.filters[FiltersEnum.SearchMode] = action.payload;
    },
    UPDATE_HAS_SEARCHED: (state, action: PayloadAction<SearchState['hasSearched']>) => {
      state.hasSearched = action.payload;
      state.page = 0;
    },
    UPDATE_PER_PAGE: (state, action: PayloadAction<SearchState['perPage']>) => {
      state.perPage = action.payload;
    },
    UPDATE_PAGE: (state, action: PayloadAction<SearchState['page']>) => {
      state.page = action.payload;
    },
    UPDATE_PERIOD: (state, action: PayloadAction<DateRange>) => {
      state.filters[FiltersEnum.Period] = serializeDateRange(action.payload);
    },
    UPDATE_MEDIUM_TYPE_GROUP: (state, action: PayloadAction<string | number>) => {
      if (state.filters[FiltersEnum.MediumTypeGroup][action.payload]) {
        state.filters[FiltersEnum.MediumTypeGroup][action.payload] = false;
      } else {
        state.filters[FiltersEnum.MediumTypeGroup][action.payload] = true;
      }
    },
    UPDATE_LANGUAGE: (state, action: PayloadAction<string | number>) => {
      if (state.filters[FiltersEnum.Language][action.payload]) {
        state.filters[FiltersEnum.Language][action.payload] = false;
      } else {
        state.filters[FiltersEnum.Language][action.payload] = true;
      }
    },
    UPDATE_COUNTRY: (state, action: PayloadAction<string | number>) => {
      if (state.filters[FiltersEnum.Country][action.payload]) {
        state.filters[FiltersEnum.Country][action.payload] = false;
      } else {
        state.filters[FiltersEnum.Country][action.payload] = true;
      }
    },
    UPDATE_TOPIC: (state, action: PayloadAction<string | number>) => {
      if (state.filters[FiltersEnum.Topics][action.payload]) {
        state.filters[FiltersEnum.Topics][action.payload] = false;
      } else {
        state.filters[FiltersEnum.Topics][action.payload] = true;
      }
    },
    UPDATE_TOPICS: (state, action: PayloadAction<(string | number)[]>) => {
      action.payload.forEach(topic => {
        if (state.filters[FiltersEnum.Topics][topic]) {
          state.filters[FiltersEnum.Topics][topic] = false;
        } else {
          state.filters[FiltersEnum.Topics][topic] = true;
        }
      });
    },
    UPDATE_SOURCE: (state, action: PayloadAction<string | number>) => {
      if (state.filters[FiltersEnum.Source][action.payload]) {
        state.filters[FiltersEnum.Source][action.payload] = false;
      } else {
        state.filters[FiltersEnum.Source][action.payload] = true;
      }
    },
    UPDATE_SOURCES: (state, action: PayloadAction<(string | number)[]>) => {
      action.payload.forEach(source => {
        if (state.filters[FiltersEnum.Source][source]) {
          state.filters[FiltersEnum.Source][source] = false;
        } else {
          state.filters[FiltersEnum.Source][source] = true;
        }
      });
    },
    UPDATE_SOURCEGROUP: (state, action: PayloadAction<string | number>) => {
      if (state.filters[FiltersEnum.SourceGroup][action.payload]) {
        state.filters[FiltersEnum.SourceGroup][action.payload] = false;
      } else {
        state.filters[FiltersEnum.SourceGroup][action.payload] = true;
      }
    },
    UPDATE_EDITION: (state, action: PayloadAction<{ id: string; sourceId: string | number }[]>) => {
      action.payload.forEach(({ id: edition }) => {
        state.filters[FiltersEnum.Edition][edition] = !state.filters[FiltersEnum.Edition][edition];
      });
    },
    UPDATE_SUBSOURCE: (
      state,
      action: PayloadAction<{ id: number | string; sourceId: number | string; label: string }[]>,
    ) => {
      action.payload.forEach(({ id, sourceId }) => {
        if (state.filters[FiltersEnum.Subsource][id]?.active) {
          state.filters[FiltersEnum.Subsource][id] = {
            ...state.filters[FiltersEnum.Subsource][id],
            active: false,
            sourceId: `${sourceId}`,
          };
        } else {
          state.filters[FiltersEnum.Subsource][id] = {
            ...state.filters[FiltersEnum.Subsource][id],
            active: true,
            sourceId: `${sourceId}`,
          };
        }
      });
    },
    CLEAR_FILTERS: (state, action: PayloadAction<Partial<SearchState['filters']>>) => {
      state.filters = {
        ...INITIAL_STATE.filters,
        ...action.payload,
        searchMode: state.filters[FiltersEnum.SearchMode],
        order: state.filters[FiltersEnum.Order],
      };
    },
    CLEAR_PERIOD: (state, action: PayloadAction<DateRange>) => {
      state.filters[FiltersEnum.Period] = serializeDateRange(action.payload);
    },
    CLEAR_MEDIUM_TYPE_GROUP: state => {
      state.filters[FiltersEnum.MediumTypeGroup] = {};
    },
    CLEAR_LANGUAGE: state => {
      state.filters[FiltersEnum.Language] = {};
    },
    CLEAR_COUNTRY: state => {
      state.filters[FiltersEnum.Country] = {};
    },
    CLEAR_TOPICS: state => {
      state.filters[FiltersEnum.Topics] = {};
    },
    CLEAR_SOURCE: state => {
      state.filters[FiltersEnum.Source] = {};
    },
    CLEAR_SOURCEGROUP: state => {
      state.filters[FiltersEnum.SourceGroup] = {};
    },
    CLEAR_ADVANCED_FILTER: state => {
      state.filters[FiltersEnum.AdvancedFilters] = {
        ...INITIAL_STATE.filters[FiltersEnum.AdvancedFilters],
      };
    },
    CLEAR_EDITION: state => {
      state.filters[FiltersEnum.Edition] = {};
    },
    CLEAR_SUBSOURCE: state => {
      state.filters[FiltersEnum.Subsource] = {};
    },
    UPDATE_ORDER: (state, action: PayloadAction<string>) => {
      state.filters[FiltersEnum.Order] = action.payload;
    },
    UPDATE_ADVANCED_FILTER: (state, action: PayloadAction<AdvancedFiltersPayload>) => {
      const { data, filter } = action.payload;

      // @ts-ignore https://github.com/microsoft/TypeScript/issues/32375#issuecomment-511073878
      state.filters[FiltersEnum.AdvancedFilters][filter] = data;
    },

    RESTORE_SEARCH: (state, action: PayloadAction<Partial<SearchState>>) => {
      return {
        ...state,
        ...action.payload,
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };
    },
    CLEAR_OPEN_NEWSOBJECT: state => {
      state.openNewsObjectUuid = undefined;
    },
  },
});

export const { actions: searchActions } = searchSlice;

export default searchSlice.reducer;
