import React from 'react';

export default {
  IcoAdd: React.lazy(() => import('./IcoAdd')),
  IcoAllcontent: React.lazy(() => import('./IcoAllcontent')),
  IcoAnalysis: React.lazy(() => import('./IcoAnalysis')),
  IcoAnalysisDonut: React.lazy(() => import('./IcoAnalysisDonut')),
  IcoAnalysisLine: React.lazy(() => import('./IcoAnalysisLine')),
  IcoArchive: React.lazy(() => import('./IcoArchive')),
  IcoArrow: React.lazy(() => import('./IcoArrow')),
  IcoArrowDownWithCircle: React.lazy(() => import('./IcoArrowDownWithCircle')),
  IcoArrowEnter: React.lazy(() => import('./IcoArrowEnter')),
  IcoArrowSub: React.lazy(() => import('./IcoArrowSub')),
  IcoArrowUp: React.lazy(() => import('./IcoArrowUp')),
  IcoArticle: React.lazy(() => import('./IcoArticle')),
  IcoAttachment: React.lazy(() => import('./IcoAttachment')),
  IcoAudio: React.lazy(() => import('./IcoAudio')),
  IcoBadge: React.lazy(() => import('./IcoBadge')),
  IcoBelga: React.lazy(() => import('./IcoBelga')),
  IcoBoard: React.lazy(() => import('./IcoBoard')),
  IcoBold: React.lazy(() => import('./IcoBold')),
  IcoBolt: React.lazy(() => import('./IcoBolt')),
  IcoBookmarkSolid: React.lazy(() => import('./IcoBookmarkSolid')),
  IcoBookmark: React.lazy(() => import('./IcoBookmark')),
  IcoBrand: React.lazy(() => import('./IcoBrand')),
  IcoCalendar: React.lazy(() => import('./IcoCalendar')),
  IcoCall: React.lazy(() => import('./IcoCall')),
  IcoCamera: React.lazy(() => import('./IcoCamera')),
  IcoChat: React.lazy(() => import('./IcoChat')),
  IcoCheck: React.lazy(() => import('./IcoCheck')),
  IcoCheckBold: React.lazy(() => import('./IcoCheckBold')),
  IcoCheckCircle: React.lazy(() => import('./IcoCheckCircle')),
  IcoCheckSmall: React.lazy(() => import('./IcoCheckSmall')),
  IcoCheckboxChecked: React.lazy(() => import('./IcoCheckboxChecked')),
  IcoCheckboxIndeterminate: React.lazy(() => import('./IcoCheckboxIndeterminate')),
  IcoCheckboxUnchecked: React.lazy(() => import('./IcoCheckboxUnchecked')),
  IcoChevronNext: React.lazy(() => import('./IcoChevronNext')),
  IcoChevronNextLast: React.lazy(() => import('./IcoChevronNextLast')),
  IcoChevronPrevious: React.lazy(() => import('./IcoChevronPrevious')),
  IcoChevronPreviousFirst: React.lazy(() => import('./IcoChevronPreviousFirst')),
  IcoClip: React.lazy(() => import('./IcoClip')),
  IcoClose: React.lazy(() => import('./IcoClose')),
  IcoCollaboration: React.lazy(() => import('./IcoCollaboration')),
  IcoComments: React.lazy(() => import('./IcoComments')),
  IcoCopyTime: React.lazy(() => import('./IcoCopyTime')),
  IcoDownload: React.lazy(() => import('./IcoDownload')),
  IcoDrag: React.lazy(() => import('./IcoDrag')),
  IcoDuplicate: React.lazy(() => import('./IcoDuplicate')),
  IcoEdit: React.lazy(() => import('./IcoEdit')),
  IcoEuro: React.lazy(() => import('./IcoEuro')),
  IcoEye: React.lazy(() => import('./IcoEye')),
  IcoEyeCrossed: React.lazy(() => import('./IcoEyeCrossed')),
  IcoFacebook: React.lazy(() => import('./IcoFacebook')),
  IcoFavourite: React.lazy(() => import('./IcoFavourite')),
  IcoFavouriteActive: React.lazy(() => import('./IcoFavouriteActive')),
  IcoFeeds: React.lazy(() => import('./IcoFeeds')),
  IcoFile: React.lazy(() => import('./IcoFile')),
  IcoFilter: React.lazy(() => import('./IcoFilter')),
  IcoFolder: React.lazy(() => import('./IcoFolder')),
  IcoForward: React.lazy(() => import('./IcoForward')),
  IcoFullscreen: React.lazy(() => import('./IcoFullscreen')),
  IcoFullscreenAlt: React.lazy(() => import('./IcoFullscreenAlt')),
  IcoFullscreenClose: React.lazy(() => import('./IcoFullscreenClose')),
  IcoFullscreenExit: React.lazy(() => import('./IcoFullscreenExit')),
  IcoGopress: React.lazy(() => import('./IcoGopress')),
  IcoGroup: React.lazy(() => import('./IcoGroup')),
  IcoHashtag: React.lazy(() => import('./IcoHashtag')),
  IcoHeading: React.lazy(() => import('./IcoHeading')),
  IcoHelp: React.lazy(() => import('./IcoHelp')),
  IcoHighlight: React.lazy(() => import('./IcoHighlight')),
  IcoHighlightActive: React.lazy(() => import('./IcoHighlightActive')),
  IcoIgnoredresults: React.lazy(() => import('./IcoIgnoredresults')),
  IcoImage: React.lazy(() => import('./IcoImage')),
  IcoImages: React.lazy(() => import('./IcoImages')),
  IcoInfo: React.lazy(() => import('./IcoInfo')),
  IcoInstagram: React.lazy(() => import('./IcoInstagram')),
  IcoItalic: React.lazy(() => import('./IcoItalic')),
  IcoKeyword: React.lazy(() => import('./IcoKeyword')),
  IcoKeywordFast: React.lazy(() => import('./IcoKeywordFast')),
  IcoKiosk: React.lazy(() => import('./IcoKiosk')),
  IcoLight: React.lazy(() => import('./IcoLight')),
  IcoLikes: React.lazy(() => import('./IcoLikes')),
  IcoLink: React.lazy(() => import('./IcoLink')),
  IcoLinkedin: React.lazy(() => import('./IcoLinkedin')),
  IcoList: React.lazy(() => import('./IcoList')),
  IcoLocation: React.lazy(() => import('./IcoLocation')),
  IcoLock: React.lazy(() => import('./IcoLock')),
  IcoLogout: React.lazy(() => import('./IcoLogout')),
  IcoLongtext: React.lazy(() => import('./IcoLongtext')),
  IcoMagazine: React.lazy(() => import('./IcoMagazine')),
  IcoMail: React.lazy(() => import('./IcoMail')),
  IcoMenu: React.lazy(() => import('./IcoMenu')),
  IcoMinus: React.lazy(() => import('./IcoMinus')),
  IcoMore: React.lazy(() => import('./IcoMore')),
  IcoMsexcel: React.lazy(() => import('./IcoMsexcel')),
  IcoMsword: React.lazy(() => import('./IcoMsword')),
  IcoNewspaper: React.lazy(() => import('./IcoNewspaper')),
  IcoNotifications: React.lazy(() => import('./IcoNotifications')),
  IcoOnline: React.lazy(() => import('./IcoOnline')),
  IcoOrderedList: React.lazy(() => import('./IcoOrderedList')),
  IcoOrganization: React.lazy(() => import('./IcoOrganization')),
  IcoOverview: React.lazy(() => import('./IcoOverview')),
  IcoPaperclip: React.lazy(() => import('./IcoPaperclip')),
  IcoPause: React.lazy(() => import('./IcoPause')),
  IcoPdf: React.lazy(() => import('./IcoPdf')),
  IcoPlayArrow: React.lazy(() => import('./IcoPlayArrow')),
  IcoPlus: React.lazy(() => import('./IcoPlus')),
  IcoPressrelease: React.lazy(() => import('./IcoPressrelease')),
  IcoPressreview: React.lazy(() => import('./IcoPressreview')),
  IcoPrint: React.lazy(() => import('./IcoPrint')),
  IcoProfile: React.lazy(() => import('./IcoProfile')),
  IcoPublish: React.lazy(() => import('./IcoPublish')),
  IcoRadio: React.lazy(() => import('./IcoRadio')),
  IcoRadiusChecked: React.lazy(() => import('./IcoRadiusChecked')),
  IcoRadiusUnchecked: React.lazy(() => import('./IcoRadiusUnchecked')),
  IcoRealtime: React.lazy(() => import('./IcoRealtime')),
  IcoRecommended: React.lazy(() => import('./IcoRecommended')),
  IcoRedo: React.lazy(() => import('./IcoRedo')),
  IcoRefresh: React.lazy(() => import('./IcoRefresh')),
  IcoReload: React.lazy(() => import('./IcoReload')),
  IcoSearch: React.lazy(() => import('./IcoSearch')),
  IcoSentimentNegative: React.lazy(() => import('./IcoSentimentNegative')),
  IcoSentimentNeutral: React.lazy(() => import('./IcoSentimentNeutral')),
  IcoSentimentPositive: React.lazy(() => import('./IcoSentimentPositive')),
  IcoSettings: React.lazy(() => import('./IcoSettings')),
  IcoShare: React.lazy(() => import('./IcoShare')),
  IcoShares: React.lazy(() => import('./IcoShares')),
  IcoShorttext: React.lazy(() => import('./IcoShorttext')),
  IcoSkipNext: React.lazy(() => import('./IcoSkipNext')),
  IcoSkipPrevious: React.lazy(() => import('./IcoSkipPrevious')),
  IcoSmallNegative: React.lazy(() => import('./IcoSmallNegative')),
  IcoSmallPositive: React.lazy(() => import('./IcoSmallPositive')),
  IcoSocial: React.lazy(() => import('./IcoSocial')),
  IcoSort: React.lazy(() => import('./IcoSort')),
  IcoSortAlphabetically: React.lazy(() => import('./IcoSortAlphabetically')),
  IcoStorytag: React.lazy(() => import('./IcoStorytag')),
  IcoStrike: React.lazy(() => import('./IcoStrike')),
  IcoStripe: React.lazy(() => import('./IcoStripe')),
  IcoTag: React.lazy(() => import('./IcoTag')),
  IcoTagRetag: React.lazy(() => import('./IcoTagRetag')),
  IcoTagSolid: React.lazy(() => import('./IcoTagSolid')),
  IcoTags: React.lazy(() => import('./IcoTags')),
  IcoTagsSolid: React.lazy(() => import('./IcoTagsSolid')),
  IcoTextblock: React.lazy(() => import('./IcoTextblock')),
  IcoTime: React.lazy(() => import('./IcoTime')),
  IcoTodayinhistory: React.lazy(() => import('./IcoTodayinhistory')),
  IcoTopic: React.lazy(() => import('./IcoTopic')),
  IcoTranlate: React.lazy(() => import('./IcoTranlate')),
  IcoTrash: React.lazy(() => import('./IcoTrash')),
  IcoTriangle: React.lazy(() => import('./IcoTriangle')),
  IcoTv: React.lazy(() => import('./IcoTv')),
  IcoTwitter: React.lazy(() => import('./IcoTwitter')),
  IcoTwitterX: React.lazy(() => import('./IcoTwitterX')),
  IcoUnderline: React.lazy(() => import('./IcoUnderline')),
  IcoUndo: React.lazy(() => import('./IcoUndo')),
  IcoUnlink: React.lazy(() => import('./IcoUnlink')),
  IcoUnorderedList: React.lazy(() => import('./IcoUnorderedList')),
  IcoUsedinfeeds: React.lazy(() => import('./IcoUsedinfeeds')),
  IcoVideo: React.lazy(() => import('./IcoVideo')),
  IcoVolumeOff: React.lazy(() => import('./IcoVolumeOff')),
  IcoVolumeOn: React.lazy(() => import('./IcoVolumeOn')),
  IcoWarning: React.lazy(() => import('./IcoWarning')),
  IcoWarningFilled: React.lazy(() => import('./IcoWarningFilled')),
  IcoYoutube: React.lazy(() => import('./IcoYoutube')),
  IcoZoomin: React.lazy(() => import('./IcoZoomin')),
  IcoZoomout: React.lazy(() => import('./IcoZoomout')),
  LoaderAnalysis: React.lazy(() => import('./LoaderAnalysis')),
  LoaderExpandedBig: React.lazy(() => import('./LoaderExpandedBig')),
  LoaderRegular: React.lazy(() => import('./LoaderRegular')),
  LoaderThin: React.lazy(() => import('./LoaderThin')),
  LogoAppStore: React.lazy(() => import('./LogoAppStore')),
  LogoBelgapress: React.lazy(() => import('./LogoBelgapress')),
  LogoBelgapressAppicon: React.lazy(() => import('./LogoBelgapressAppicon')),
  LogoBelgapressNew: React.lazy(() => import('./LogoBelgapressNew')),
  LogoGooglePlay: React.lazy(() => import('./LogoGooglePlay')),
};
