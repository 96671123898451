import { DateRangeType } from 'utils/date.utils';

import { SourceGroupNames } from 'constants/source.constants';

import { Tag } from './tag.types';
import { Topic } from './topic.types';

export enum NewsObjectType {
  NEWS = 'NEWS',
  CLIPPING = 'CLIPPING',
  DOCUMENT = 'DOCUMENT',
  BELGA_PHOTO = 'BELGA_PHOTO',
  BELGA_COVERAGE = 'BELGA_COVERAGE',
  BELGA_VIDEO = 'BELGA_VIDEO',
}

export enum NewsObjectActions {
  Clipping = 'Clipping',
  Label = 'Label',
  Share = 'Share',
  Print = 'Print',
  Keyword = 'Keyword',
  Save = 'Save',
  FastLabel = 'FastLabel',
  DeleteClipping = 'DeleteClipping',
  FastKeyword = 'FastKeyword',
}

export type SourceId = number;

export type Reference = {
  mimeType: string;
  representation: RepresentationType;
  href: string;
  reference?: string;
};

export type NewsObjectMetaData = {
  newsroomId: number;
  pressReleaseId: number;
};

export type Attachment = {
  title: string | null;
  type: AttachmentType;
  date: Date | string | number;
  source: string | null;
  from: number;
  to: number;
  duration: number;
  references: Reference[];
};

export type MediaValue = {
  amount: string;
  currency: string;
};

export type NewsObjectRefZone = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type NewsObjectRef = {
  uuid: string;
  title: string;
  zones?: NewsObjectRefZone[];
};

export enum AccessStatus {
  Included = 'INCLUDED',
  Purchasable = 'PURCHASABLE',
  Purchased = 'PURCHASED',
  Copyrighted = 'COPYRIGHTED',
  QuotaExceeded = 'QUOTA_EXCEEDED',
}

export type NewsObject = {
  uuid: string;
  title: string;
  lead: string;
  body?: string;
  createDate?: string;
  publishDate: string;
  sourceLogo: string;
  source: string;
  sourceGroup?: SourceGroupNames;
  duplicates: number;
  mediumType: MediumType;
  mediumTypeGroup: MediumTypeGroup;
  subSource: string;
  editions: string[];
  publisher: string;
  topics?: Topic[];
  page: number;
  language: string;
  authors: string[];
  attachments: Attachment[];
  wordCount: number;
  account?: string;
  sentiment?: number;
  mediaValue: MediaValue | null;
  audience: number | null;
  subSourceId: number;
  subSourceGroup?: string;
  sourceGroupId: number;
  subSourceGroupId: number;
  mediumTypeId: number;
  mediumTypeGroupId: number;
  sourceId: SourceId;
  categories: string[];
  entities: string[];
  topic?: string;
  tags: Tag[];
  type: NewsObjectType;
  accessStatus?: AccessStatus;
  retentionPeriod: number;
  // The properties below don't actually exist on the NewsObject, only on NewsObjectCompact, but leaving them out gives type-errors when unioning the two types
  note?: never;
  customTitle?: never;
  customBody?: never;
  customLead?: never;
  serializedTextMarks?: never;
  parentUuid?: string | null;
};

export type GetNewsObjectParams = {
  highlight?: boolean;
  board?: string;
  searchtext?: string;
  exactquery?: boolean;
};

export type GetNewsObjectsPriceParams = {
  newsuuids: string[];
};

export type PurchaseNewsObjectsRequestBody = { uuid: string }[];

export type Price = {
  amount: string;
  currency: string;
  includesVat?: boolean;
};

export type NewsObjectPrice = {
  uuid: string;
  price: Price;
};

export type PurchasedNewsObject = {
  uuid: string;
  title: string;
  source: {
    id: number;
    name: string;
    logo: string;
  };
  publishDate: string;
  purchaseDate: string;
  purchaser: {
    id: number;
    firstName: string;
    lastName: string;
  };
  price: Price;
};

export enum PurchasedNewsObjectOrderBy {
  Source = 'SOURCE',
  Title = 'TITLE',
  PublishDate = 'PUBLISH_DATE',
  Purchaser = 'PURCHASER',
  PurchaseDate = 'PURCHASE_DATE',
  Price = 'PRICE',
}

export type GetPurchasedNewsObjectsParams = {
  count?: number;
  from?: string;
  to?: string;
  offset?: number;
  order?: string;
};

export type SearchParams = Partial<{
  country: string;
  order: string;
  mediumtype: string;
  mediumtypegroup: string;
  keyword: string;
  source: string;
  sourcegroup: string;
  sentiment: string;
  mediavaluemin: number;
  mediavaluemax: number;
  audiencereachmin: number;
  audiencereachmax: number;
  sentimentmin: number;
  sentimentmax: number;
  pagenr: string;
  language: string;
  highlight: boolean;
  searchtext: string;
  board: string;
  feedid: number;
  savedsearchid: number;
  start: string;
  end: string;
  periodType: DateRangeType;
  edition: string;
  newsuuids: string;
  mediumtypeid: string | number;
  mediumtypegroupid: string | number;
  sourceid: string | number | number[];
  sourcegroupid: string;
  subsource: string;
  subsourcegroup: string;
  subsourcegroupid: string | number;
  subsourceid: string | number;
  publisher: string;
  subscription: boolean;
  count: string | number;
  offset: string | number;
  tagid: number | string;
  topicids: string[];
  subscribe: boolean;
  excludednewsobjectuuids: string[];
  collapseduplicates: boolean;
  mustcontain: MustContainField[];
  snippets: boolean;
  types: string;
  parentuuid: string;
}>;

export type PostSearchParams = Partial<{
  author: string[];
  country: string;
  order: string[];
  mediumtype: string;
  mediumtypegroup: string[];
  keyword: (string | number)[];
  source: string;
  sourcegroup: string;
  sentiment: string;
  mediavaluemin: number;
  mediavaluemax: number;
  audiencereachmin: number;
  audiencereachmax: number;
  sentimentmin: number;
  sentimentmax: number;
  pagenr: string;
  language: string[];
  highlight: boolean;
  searchtext: string;
  board: string;
  start: string;
  end: string;
  periodType: DateRangeType;
  edition: string[];
  newsuuids: string;
  mediumtypeid: string | number;
  mediumtypegroupid: [];
  sourceid: string[];
  sourcegroupid: string[];
  subsource: string;
  subsourcegroup: string;
  subsourcegroupid: [];
  subsourceid: string[];
  publisher: (string | number)[];
  subscription: boolean;
  count: number;
  offset: number;
  tagid: (string | number)[];
  topicids: string[];
  subscribe: boolean;
  excludednewsobjectuuids: string[];
  collapseduplicates: boolean;
  mustcontain: MustContainField[];
  snippets: boolean;
  types: string;
}>;

export type NewsObjectQuery = {
  [key: string]: string | string[] | number;
};

export type UserTagQuery = {
  [key: string]: string | string[] | number | boolean;
};

export type ThumbnailAttachment = {
  title: string | null;
  reference: Reference;
  attachmentType: AttachmentType;
  thumbnail?: Reference;
  from: number;
  to: number;
  duration: number;
};

export type SnippetParams = {
  query?: string;
  board?: string;
};

export type NewsObjectCompact = {
  uuid: string;
  source: string;
  sourceLogo: string | null;
  mediumType: MediumType;
  mediumTypeGroup: MediumTypeGroup;
  publishDate: string;
  language: string;
  title?: string;
  lead?: string;
  thumbUrl?: string;
  order?: number;
  note?: string;
  customTitle?: string | null;
  customLead?: string | null;
  customBody?: string | null;
  serializedTextMarks?: string | null;
  accessStatus?: AccessStatus;
  type: NewsObjectType;
};

export type BelgaContextId = string | number;

export type NewsObjectContext = {
  belgaContext?: BelgaContext;
  belgaContextId?: BelgaContextId;
};

export type LabelSummaryContext = {
  newsletterId: number;
  newsletterItemId: number | undefined;
};

export enum ReferenceMimeType {
  NotSpecified = 'NOT_SPECIFIED',
  Png = 'PNG',
  Jpg = 'JPG',
  Jpeg = 'JPEG',
  M3u8 = 'M3U8',
  Html = 'HTML',
}

export enum RepresentationType {
  original = 'ORIGINAL',
  small = 'SMALL',
  medium = 'MEDIUM',
  print = 'PRINT',
  detail = 'DETAIL',
  stream = 'STREAM',
  playerYoutube = 'YT_PLAYER',
  playerMd = 'MD_PLAYER',
  download = 'DOWNLOAD',
}

export enum AttachmentType {
  attachment = 'Attachment',
  image = 'Image',
  player = 'Player',
  page = 'Page',
  audio = 'Audio',
  video = 'Video',
  webPage = 'Webpage',
  twitter = 'Twitter',
  instagram = 'Instagram',
  rtv = 'Rtv',
  youtube = 'Youtube',
  belgaImageRef = 'BelgaImageRef',
}

export enum MediumTypeGroupId {
  print = 1,
  online = 2,
  social = 3,
  belga = 4,
  multimedia = 5,
  other = 6,
}

export enum MediumTypeGroup {
  PRINT = 'PRINT',
  ONLINE = 'ONLINE',
  SOCIAL = 'SOCIAL',
  BELGA = 'BELGA',
  MULTIMEDIA = 'MULTIMEDIA',
  OTHER = 'OTHER',
}

export enum MediumType {
  radio = 'RADIO',
  tv = 'TELEVISION',
  video = 'VIDEO',
  twitter = 'TWITTER',
  newspaper = 'NEWSPAPER',
  website = 'WEBSITE',
  belganews = 'BELGANEWS',
  magazine = 'MAGAZINE',
  belga_alert = 'BELGAALERT',
  instagram = 'INSTAGRAM',
  facebook = 'FACEBOOK',
  youtube = 'YOUTUBE',
  photo_coverage = 'PHOTOCOVERAGE',
  audio = 'AUDIO',
  press_release = 'PRESS_RELEASE',
  agenda = 'AGENDA',
  today_in_history = 'TODAY_IN_HISTORY',
  coming_up = 'COMING_UP',
  alerts = 'ALERTS',
  wire = 'WIRE',
}
export enum BelgaContext {
  BOARD_OVERVIEW = 'BOARD_OVERVIEW',
  BOARD_ALL_CONTENT = 'BOARD_ALL_CONTENT',
  BOARD_BELGA = 'BOARD_BELGA',
  KIOSK = 'KIOSK',
  KIOSK_EXPLORE = 'KIOSK_EXPLORE',
  KIOSK_REAL_TIME = 'KIOSK_REAL_TIME',
  KIOSK_SOURCE = 'KIOSK_SOURCE',
  KIOSK_LIVE_FEED = 'KIOSK_LIVE_FEED',
  KIOSK_NEWSLETTER = 'KIOSK_NEWSLETTER',
  SEARCH = 'SEARCH',
  FAVORITES = 'FAVORITES',
  LABELS = 'LABELS',
  FEEDS = 'FEEDS',
  SHARE = 'SHARE',

  // Belga share context
  BELGA_SHARE_TAB_DOCUMENTS = 'BELGA_SHARE_TAB_DOCUMENTS',
  BELGA_SHARE_TAB_PRESS_RELEASES = 'BELGA_SHARE_TAB_PRESS_RELEASES',
  BELGA_SHARE_TAB_PRESS_COVERAGES = 'BELGA_SHARE_TAB_PRESS_COVERAGES',
  BELGA_SHARE_TAB_EVENTS = 'BELGA_SHARE_TAB_EVENTS',
  BELGA_SHARE_NEWSROOM = 'BELGA_SHARE_NEWSROOM',
  BELGA_SHARE_PRESS_RELEASE = 'BELGA_SHARE_PRESS_RELEASE',
}

export enum MustContainField {
  SENTIMENT = 'SENTIMENT',
  MEDIA_VALUE = 'MEDIA_VALUE',
  AUDIENCE = 'AUDIENCE',
}
